<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/checkAttendance' }">考勤情况</el-breadcrumb-item>
      <el-breadcrumb-item :to="{
        path: '/zuzhi/organizationList/myOrganizationList1',
        query: {
          oid: this.oid,
          fatherName: this.fatherName
        }
      }">我的组织详情</el-breadcrumb-item>

      <el-breadcrumb-item>正在签到</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="result-container" v-if="flag">
      <el-card shadow="never" style="margin-top: 15px">
        <el-row :gutter="20" style="
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 0.5px solid #eee;
          ">
          <el-col :span="15">
            <span class="title">{{ title }}</span>
            <span class="className" style="margin-right: 15px; margin-left: 15px">
              名单:
              <el-tag type="warning">
                {{ className == 'undefined' ? '无名单' : className }}
              </el-tag>
            </span>
            <span class="linkType">
              签到环节: <el-tag>{{ linkType == 1 ? '签到' : '签退' }}</el-tag>
            </span>
            <!-- <div
              style="display: inline"
              v-if="status == 1 || signStopType == 1"
            >
              <span class="randomnum">
                随机签到码:<span
                  style="font-size: 35px; font-weight: bolder"
                  v-if="isshowrandomnum"
                  >{{ randomnum }}</span
                >
              </span>
              <el-button
                type="success"
                plain
                size="mini"
                v-if="isshowrandomnum"
                @click="isshowrandomnum = false"
                >隐藏</el-button
              >
              <el-button
                type="success"
                plain
                size="mini"
                v-if="!isshowrandomnum"
                @click="isshowrandomnum = true"
                >显示</el-button
              >
            </div> -->
          </el-col>
          <el-col :span="9" style="text-align: right">
            <span v-if="classNum == 0" style="font-size: 23px; font-weight: bold; margin-right: 40px">{{ linkType == 1 ?
              '签到人数：' : '签退人数：'
              }}{{ signCount }}</span>
            <span v-else style="font-size: 23px; font-weight: bold; margin-right: 40px">{{ linkType == 1 ? '签到人数：' :
              '签退人数：'
              }}{{ signCount }} / {{ classNum }}</span>
            <!-- <span
              v-if="isShow && (signStopType == 0 || signStopType == -1)"
              class="title"
              style="color: #ed1c24"
            >
              剩余:{{ min }}:{{ sec }}
            </span>
            <span
              v-if="status == 2 && (signStopType == 0 || signStopType == -1)"
              class="title"
              style="color: #ed1c24"
            >
              签到已结束！
            </span>
            <span
              v-if="status == 0 && (signStopType == 0 || signStopType == -1)"
              class="title"
              style="color: #36a62a"
            >
              签到未开始！
            </span> -->
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="14" style="padding-top: 20px;height: 500px">
            <div class="statistics-layout" style="height: 500px;border-radius: 8px;">
              <div class="layout-title">签到统计</div>
              <div style="padding: 5px">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        课程总人数
                      </div>
                      <div style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        ">
                        {{ classNum }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        {{ linkType == 1 ? '已签到人数' : '已签退人数' }}
                      </div>
                      <div style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        ">
                        {{ signCount }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        {{ linkType == 1 ? '未签到人数' : '未签退人数' }}
                      </div>
                      <div style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        ">
                        {{ unsignnum }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="chart-wrapper">
                    <bar-chart :barData="histogramData" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="chart-wrapper">
                    <pie-chart :pieData="pieData" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="10" style="padding-top: 20px">
            <el-tabs type="border-card" class="box-card" style="overflow-y: auto; overflow-x: hidden" ref="cardContent">
              <el-tab-pane :label="linkType == 1 ? '已签到' : '已签退'">
                <!-- 已签到列表元素新增渐变  -->
                <transition-group>
                  <div style="
                      text-align: center;
                      width: 70px;
                      height: 90px;
                      float: left;
                    " v-for="item in listSign" :key="item.name">
                    <el-avatar :size="50" :src="item.avatar"></el-avatar>
                    <div>{{ item.name }}</div>
                  </div>
                </transition-group>
              </el-tab-pane>
              <el-tab-pane :label="linkType == 1 ? '未签到' : '未签退'">
                <!-- 未签到列表元素新增渐变  -->
                <transition-group>
                  <div style="
                      text-align: center;
                      width: 70px;
                      height: 90px;
                      float: left;
                    " v-for="item in unsignlist" :key="item.name">
                    <el-avatar :size="50" :src="item.avatar"></el-avatar>
                    <div>{{ item.name }}</div>
                  </div>
                </transition-group>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="result-container" v-else>
      <div class="noSign">该课程暂无正在签到！！！</div>
    </div>
  </div>
</template>

<script>
import PieChart from '../../../home/components/PieChart.vue'
import BarChart from '../../..//home/components/BarChart'
export default {
  name: 'signcode',
  components: {
    PieChart,
    BarChart
  },
  data() {
    this.histogramSettings = {
      yAxisType: 'normal',
      min: 0,
      digit: 0
    }
    this.pieSettings = {
      roseType: 'radius'
      // radius: [15, 95],
      // center: ['50%', '38%'],
    }
    return {
      ccid: '', //课程id
      oid: '',
      fatherName: '',
      flag: false,
      histogramData: {
        xdata: ['已签到', '未签到'],
        arrdata: [50, 5]
      },

      pieData: {
        xdata: ['其他', '未签到', '旷课', '请假', '迟到', '签到', '未认证'],
        arrdata: [
          { value: 12, name: '已签到' },
          { value: 1, name: '未签到' },
          { value: 0, name: '其他' },
          { value: 3, name: '旷课' },
          { value: 1, name: '未认证' }
        ]
      },
      signnum: 0,
      unsignnum: 0,
      totalnum: 0,
      // //是否展示随机签到码
      // isshowrandomnum: false,
      // //随机签到码
      // randomnum: '',
      code: '',
      actId: '',
      lid: '',
      title: '',
      linkType: null,
      className: '',
      timer: null,
      finalTime: '',
      isEnd: false,
      min: '',
      sec: '',
      screenWidth: null,
      isShow: false,
      peopleCnt: 0,
      path: 'wss://xiangqian.club:8001/ws/asset',
      socket: '',
      status: '',
      //未签到的列表
      unsignlist: [],
      //已签到的列表
      listSign: [],
      classNum: 0,
      totalnum: 0,
      signStopType: 0,
      signCount: 0
    }
  },
  created() {
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    this.finalTime = this.$route.query.EndTime
    this.status = this.$route.query.status
    // this.lid = window.sessionStorage.getItem('lid')
    // this.cid = window.sessionStorage.getItem('cid').toString()
    // this.actId = window.sessionStorage.getItem('actId')
    // this.title = window.sessionStorage.getItem('title')
    // this.signStopType = window.sessionStorage.getItem('signStopType')
    // this.linkType = window.sessionStorage.getItem('linkType')
    // this.className = window.sessionStorage.getItem('className')
    // this.oid = window.sessionStorage.getItem('soid')
    this.fatherName = this.$route.query.fatherName
    this.oid = this.$route.query.oid
    this.ccid = this.$route.query.ccid
    // this.getSignList()
    // this.getunSignList()
    this.getNowSign()
    // this.getrandomnum()
    // if (this.cid != '' && this.cid != 'undefined' && this.cid != null) {
    //   // this.selectClassNum()
    // }
    // this.initFakeData()
  },
  mounted() {
    // this.countdown()
    // this.initFakeData()

    this.init()
  },
  destroyed() {
    // clearInterval(this.timer)
    // this.end()
    if (window.sessionStorage.getItem('socketConnection') == 1) {
      this.close()
      window.sessionStorage.setItem('socketConnection', 0)
    }
  },
  methods: {
    async getNowSign() {
      const { data: res } = await this.$http.get('/rec/resultTeacher3', {
        params: {
          ccid: this.ccid
        }
      })
      if (res.code == 200) {
        this.flag = true
        this.className = res.data[3]
        this.linkType = res.data[5]
        this.listSign = res.data[2]
        this.unsignlist = res.data[9]
        this.classNum = res.data[4]
        this.signCount = res.data[2].length
        this.unsignnum = res.data[9].length
        this.histogramData.arrdata = [this.signCount, this.unsignnum]
        this.pieData.arrdata = [
          { value: res.data[1]['签到'], name: '签到' },
          { value: res.data[1]['迟到'], name: '迟到' },
          { value: res.data[1]['其他'], name: '其他' },
          { value: res.data[1]['旷课'], name: '旷课' },
          { value: res.data[1]['请假'], name: '请假' },
          { value: res.data[1]['未认证'], name: '未认证' },
          { value: this.unsignnum, name: '未签到' }
        ]
      } else {
        this.flag = false
      }
    },

    // initFakeData() {
    //   this.flag = true
    // },
    // socket连接
    // init: function() {
    //   if (typeof WebSocket === 'undefined') {
    //     alert('您的浏览器不支持socket')
    //   } else if (window.sessionStorage.getItem('socketConnection') == 0) {
    //     // 实例化socket
    //     this.socket = new WebSocket(this.path)
    //     // 监听socket连接
    //     this.socket.onopen = this.open
    //     // 监听socket错误信息
    //     this.socket.onerror = this.error
    //     // 监听socket消息
    //     this.socket.onmessage = this.getMessage
    //   } else return
    // },

    // 获取签到列表
    async getSignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        this.classNum = res.data[4]
        this.className = res.data[3]
        this.listSign = res.data[2]
        this.linkType = res.data[5]
        this.finalTime = res.data[7]
        this.status = res.data[8]
        // console.log('判断一下', this.status)
        this.signStopType = res.data[6]
        // this.listSign.reverse()
        this.signCount = this.listSign.length
        // this.totalnum += this.signCount
      } else {
        this.signlist = []
        this.signnum = 0
      }
      // const { data: res } = await this.$http.get('rec/resultTeacher', {
      //   params: {
      //     lid: this.lid,
      //   },
      // })
      //
      // if (res.code == 200) {
      //   console.log('list结果', res.data)
      //   this.signlist = res.data[2]
      //   this.signnum = res.data[2].length
      //   // this.totalnum += this.signnum
      //   if (!this.hasAdd) {
      //     this.totalnum += this.signnum
      //     this.hasAdd = true
      //   }
      // } else {
      //   this.signlist = []
      //   this.signnum = 0
      // }
    },
    //获取未签到列表
    async getunSignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher2', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        this.unsignlist = res.data
        this.unsignnum = res.data.length
        // this.totalnum += this.unsignnum
        // this.unsignlist.reverse()
      } else {
        this.unsignlist = []
        this.unsignnum = 0
      }
      // const { data: res } = await this.$http.get('rec/resultTeacher2', {
      //   params: {
      //     lid: this.lid,
      //   },
      // })
      // console.log('res=>', res)
      // if (res.code == 200) {
      //   console.log(res)
      //   if(res.data[2]){
      //     this.unsignlist = res.data
      //     this.unsignnum = res.data.length
      //     // this.totalnum += this.unsignnum
      //   }
      //   if (!this.hasAdd) {
      //     this.totalnum += this.unsignnum
      //     this.hasAdd = true
      //   } else {
      //   }
      //   this.histogramData.arrdata = [this.signnum, this.unsignnum]
      //
      //   // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
      //   this.flag = true
      // } else {
      //   this.unsignlist = []
      //   this.unsignnum = 0
      //   if (!this.hasAdd) {
      //     this.totalnum += this.unsignnum
      //     this.hasAdd = true
      //   }
      //   this.histogramData.arrdata = [this.signnum, this.unsignnum]
      //   // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
      //   this.flag = true
      // }
    }

    // // 查询班级人数
    // selectClassNum: async function() {
    //   const { data: res } = await this.$http.get('/class/selectClassNum', {
    //     params: {
    //       cid: this.cid
    //     }
    //   })
    //   if (res.code == 200) {
    //     this.classNum = res.data
    //   }
    // },
    // // 倒计时
    // countdown: function() {
    //   const end = Date.parse(this.finalTime)
    //   const now = Date.parse(new Date())
    //   const msec = end - now
    //   let min = parseInt((msec / 1000 / 60) % 60)
    //   let sec = parseInt((msec / 1000) % 60)
    //   // 签到进行中
    //   if (this.status == 1) {
    //     if ((min > 0 && sec >= 0) || (min == 0 && sec > 0)) {
    //       this.isShow = true
    //     } else {
    //       this.isShow = false
    //     }
    //     if (min <= 0 && sec <= 0) {
    //       this.status = 2
    //     }
    //   }
    //   this.min = min > 9 ? min : '0' + min
    //   this.sec = sec > 9 ? sec : '0' + sec
    //   const that = this
    //   this.sct = setTimeout(function() {
    //     that.countdown()
    //   }, 1000)
    // },
    // end() {
    //   clearTimeout(this.sct) //清除
    // }
  }
}
</script>

<style scoped>
.title,
.className,
.linkType {
  text-align: center;
}

.randomnum {
  text-align: center;
  margin: 10px 10px;
}

.title {
  color: #39b54a;
  font-size: 25px;
}

.className,
.linkType {
  margin: 10px 0px;
}

.statistics-layout {
  border: 1px solid #dcdfe6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #f2f6fc;
  font-weight: bold;
}

.box-card {
  height: 500px;
}

/* 列表元素新增渐变 */
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}

.share {
  float: right;
  margin-top: -80px;
}

.noSign {
  font-size: 25px;
  font-weight: bold;
  color: skyblue;
  margin: 300px 420px;
}
</style>
